<template>
  <div class="application-page d-flex">
    <div class="left-content">
      <!--      active job section-->
      <div class="applied-job-section">
        <h2>Job application history</h2>
        <div v-for="activeJob in activeJobs"
             class="applied-job-box d-flex justify-content-between align-items-center">
          <div class="job-name">
            <p>You have applied for the job,</p>
            <h4>
              <router-link :to="{ name : 'jobProgress', params : {uuid: activeJob.unique_id}}">
                {{ activeJob.job.title }}
              </router-link>
            </h4>
            <p class="mb-0">at {{ activeJob.created_at }}</p>
          </div>
          <div class="job-status">
            <p>Exp Salary</p>
            <h5 class="text-capitalize" :class="checkStatus(activeJob.status)">{{ activeJob.expected_salary }}</h5>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="right-content">
      <Calender/>
    </div> -->
  </div>
</template>

<script>
import Calender from "../../components/Calender";
import axios from "@/axios";

export default {
  name: "Application",
  components: {Calender},
  data: () => ({
    activeJobs: [],
    inactiveJobs: [
      {name: 'UX/UI Designer', status: 'pending', date: '10th July 2021'},
    ],
    rejectedApplications: [
      {name: 'UX/UI Designer', status: 'rejected', date: '10th July 2021'},
    ],
  }),
  methods: {
    checkStatus(status) {
      let data = "";
      switch (status) {
        case "pending":
          data = "status-pending";
          break;
        case "reviewed":
          data = "status-reviewed";
          break;
        case "rejected":
          data = "status-rejected";
          break;
      }
      return data;
    },
    fetchJob() {
      axios.get('/apply/').then(response => {
        this.activeJobs = response.data
      }).catch(error => {
        console.error(error.response)
      })
    }
  },
  async mounted() {
    await this.fetchJob()
  }
}
</script>

<style scoped>

</style>